
.color {
  color: #114dcf !important;
}

/* --------- */
/*  Preloader */
/* --------- */
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: white;
  background-image: url(./Assets/sup/pre.svg);
  background-repeat: no-repeat;
  background-position: center;
}

#preloader-none {
  opacity: 0;
}

#no-scroll {
  overflow: hidden;
  height: 100vh;
}

/* --------- */
/*Scrollbar   */
/* --------- */

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #001412;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #000000;
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #000000;
  border-radius: 12px;
}

/* --------- */
/* Navbar Section  */
/* --------- */
.sticky {
  opacity: 0.95;
  transition: all 0.3s ease-out 0s !important;
  backdrop-filter: blur(15px) !important;
}

.navbar {
  position: fixed !important;
  transition: all 0.3s ease-out 0s !important;
  padding: 0.3rem 2rem !important;
  font-size: 1.2rem !important;
}

.navbar-toggler {
  padding: 0.25rem 0rem !important;
  position: relative !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.navbar-toggler span {
  display: block !important;
  background-color: #000000 !important;
  height: 4px !important;
  width: 27px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  transform: rotate(0deg) !important;
  left: 0 !important;
  opacity: 1 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0 !important;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  transition: transform 0.35s ease-in-out !important;
  transition: transform 0.35s ease-in-out !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(135deg) !important;
  opacity: 0.9 !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px !important;
  visibility: hidden !important;
  background-color: transparent !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(-135deg) !important;
  opacity: 0.9 !important;
}

@media (max-width: 767px) {
  .navbar {
    padding: 1rem 2rem !important;
    font-size: 1.4rem !important;
    background-color: #00000000 !important;
    opacity: 0.95;
  }
  .navbar-nav .nav-item a::after {
    display: none !important;
  }
}
.navbar-brand {
  color: rgb(250, 250, 250) !important;
}

.logo {
  height: 11em !important;
}

.navbar-nav .nav-link {
  color: #000000 !important;
  font-weight: 700 !important;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.nav-link {
  padding: 0.8rem 1rem !important;
}

@media (max-width: 767px) {
  .nav-link {
    padding: 0.7rem 1rem !important;
    background-color: #417dfd;
  }
  .logo {
    height: 4em !important;
    width: 4.5em !important;
  }
}

.navbar-nav .nav-item {
  position: relative;
  margin-left: 20px;
}

.navbar-nav .nav-item a {
  font-size: 1rem;
  margin: 0 auto;
  transition: all 0.3s ease-out 0s;
  position: relative;
  z-index: 1;
}

.navbar-nav .nav-item a::after {
  content: '';
  position: relative;
  display: block;
  height: 5px;
  width: 0;
  border-radius: 16px;
  background: #000000;
  bottom: 1px;
  left: 0;
  z-index: -1;
  transition: all 0.3s ease-out 0s;
}

.navbar-nav .nav-item a:hover::after {
  width: 100%;
}

.button {
  width: 7rem;
  height: 3rem;
  border-radius: 2rem;
  color: #ffffff;
  background-image: linear-gradient(90deg, rgb(144, 172, 233) 0%, #417dfd 100%);
}

/* --------- */
/* Home */
/* --------- */
.home-header {
  padding-top: 80px !important;
}

.home-content {
  max-width: 70% !important;
  padding: 15rem 0 0 !important;
  color: whitesmoke;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
}

.home-content-top {
  width: 65%;
  margin: 0 auto;
  text-align: center;
}

.home-text {
  color: #000000;
  font-size: 52px;
  font-weight: 700;
}
.home-text2 {
  color: white;
  font-size: 15px;
}
.home-text3 {
  color: #000000;
  font-size: 18px;
}
.home-text4 {
  color: white;
  font-size: 20px;
  font-weight: 700;
}

.home-social {
  width: 30%;
  margin: 0 auto;
  display: flex;
}

.home-social-top {
  display: flex;
}

.home-social-bot {
  display: flex;
}

.home-socials {
  width: auto;
  border-radius: 50%;
  transition: 0.5s;
  transform: scale(0.9);
  padding-right: 0.2rem;
}

.home-socials a {
  width: auto;
}

.home-socials img {
  width: 5.5rem;
  border-radius: 50%;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.social-icons {
  display: inline-block !important;
  padding-right: 15px;
  padding-left: 15px;
}

.social-icons:hover {
  color: #000000;
}

.icon-colour {
  color: #000000 !important;
}

@media (max-width: 980px) {
  .home-content {
    padding: 9rem 0 0 !important;
  }
  .home-content-top {
    width: 100%;
  }

  .home-text {
    font-size: 1.3rem;
  }
  .home-text2 {
    font-size: 0.6rem;
  }
  .home-text3 {
    font-size: 1rem;
  }
  .home-text4 {
    font-size: 0.7rem;
  }

  .home-social {
    width: 50%;
    display: block;
  }
  .home-social-top {
    display: inline-flex;
  }
  .home-social-bot {
    display: inline-flex;
  }

  .home-socials img {
    width: 4rem;
  }
  .home-header {
    padding-top: 1px !important;
  }
}

/* --------- */
/* about */
/* --------- */
.about-section {
  position: relative;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 7rem !important;
}

.about-content {
  width: 70%;
  margin: 0 auto;
}

.about-content .row {
  margin-bottom: 4rem;
}

.about-top {
  border-radius: 2rem;
  padding: 2rem;
  z-index: 999;
  background-color: transparent;
  background-image: linear-gradient(180deg, #95b3f3 -5%, #417dfd 100%);
}

.about-text {
  color: #000000;
  font-size: 60px;
  font-weight: 700;
}
.about-text2 {
  color: #ffffff;
  font-size: 29px;
  font-weight: 900;
}
.about-text3 {
  color: #a0aec0;
  font-size: 20px;
}
.about-text4 {
  color: #ffffff;
  font-size: 20px;
}
.about-text5 {
  color: #a0aec0;
  font-size: 20px;
}
.about-text6 {
  color: #ffffff;
  font-size: 20px;
}

.videoAbout {
  border-radius: 3%;
}

@media (max-width: 980px) {
  .about-section {
    padding: 1rem !important;
  }
  .about-content {
    width: 100%;
  }

  .about-text {
    font-size: 1.5rem;
  }
  .about-text2 {
    font-size: 0.8rem;
    font-weight: 700;
  }
  .about-text3 {
    font-size: 0.7rem;
  }
  .about-text4 {
    font-size: 1rem;
  }
  .about-text5 {
    font-size: 0.7rem;
  }
  .about-text6 {
    font-size: 0.8rem;
  }

  .videoAbout {
    width: 17rem;
  }
  .imgAbout {
    width: 250px;
  }
}

#dexscreener-embed {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
}
@media (min-width: 1400px) {
  #dexscreener-embed {
    padding-bottom: 35%;
  }
}
#dexscreener-embed iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 0;
}

/* --------- */
/* roadmap */
/* --------- */
.roadmap-section {
  position: relative !important;
  padding-top: 110px !important;
  padding-bottom: 30px !important;
}

.roadmap {
  padding-top: 10px;
  padding-bottom: 50px;
}

.roadmap-left {
  padding-right: 80px !important;
}

.roadmap-right {
  padding-left: 80px !important;
}

.roadmap-text {
  color: rgb(121, 116, 116);
  font-size: 80px;
  font-weight: 700;
  background: -webkit-linear-gradient(rgb(255, 255, 255), rgb(92, 85, 85));
  -webkit-text-fill-color: transparent;
}

.roadmap .roadmap-title {
  color: #0f317a;
  font-size: 3em;
  font-weight: 700;
  padding-top: 30px;
  padding-bottom: 30px;
}

.roadmap .roadmap-item {
  padding: 0 0 10px 25px;
  margin-top: -2px;
  border-left: 2px solid #417dfd;
  position: relative;
}

.roadmap .roadmap-item .roadmap-title {
  line-height: 16px;
  font-size: 1.1em;
  color: white;
  background: #417dfd;
  padding: 8px 15px;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 1px;
}

.roadmap .roadmap-item ul {
  padding-left: 20px;
  text-align: justify;
}

.roadmap .roadmap-item ul li {
  padding-bottom: 10px;
  list-style: none;
}

.roadmap .roadmap-item:last-child {
  padding-bottom: 0;
}

.roadmap .roadmap-item::before {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50px;
  left: -9px;
  top: 0;
  background: #fff;
  border: 2px solid #417dfd;
}

@media (max-width: 980px) {
  .roadmap-text {
    font-size: 4rem;
    font-weight: 700;
  }

  .roadmap-left {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }

  .roadmap-right {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
}

/* --------- */
/* tokenomics */
/* --------- */
.tokenomics-section {
  padding-bottom: 7rem 0;
}

.tokenomics-content {
  border-radius: 2rem;
  padding: 2rem;
  z-index: 999;
  margin-left: 0 !important;
  background-color: transparent;
  background-image: linear-gradient(180deg, #94b2f5 0%, #417dfd 100%);
}

.token-text {
  color: #ffffff;
  font-size: 2.5rem;
  font-weight: 700;
}
.token-text2 {
  color: #ffffff;
  font-size: 1rem;
  font-weight: 400;
}
.token-text3 {
  color: #ffffff;
  font-size: 1.6rem;
  font-weight: 400;
}
.token-text3 li {
  list-style-type: none;
}

@media (max-width: 980px) {
  .imgToken {
    width: 14rem;
  }

  .token-text {
    font-size: 2rem;
  }
  .token-text2 {
    font-size: 0.8rem;
  }
  .token-text3 {
    font-size: 1.3rem;
    padding: 0;
  }
  .token-text3 h3 {
    font-size: 1.2rem;
  }
}

/* --------- */
/* Footer */
/* --------- */
.footer {
  background-color: #001412;
  opacity: 0.7;
  bottom: 0 !important;
  padding-top: 10px !important;
  padding-bottom: 8px !important ;
}
.footer-copywright {
  text-align: center !important;
}

.footer-body {
  text-align: center !important;
}

@media (max-width: 767px) {
  .footer-copywright {
    text-align: center !important;
  }

  .footer-body {
    text-align: center !important;
  }
}

.footer h3 {
  font-size: 1em;
  color: white !important;
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
}

.footer h1 {
  font-size: 0.7em;
  color: white !important;
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
}
.footer-icons {
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
  padding: 0 !important;
}

.blockquote-footer {
  color: #000000 !important;
}
